import React from 'react';

import Page from '~/app/components/Page';
import SongwhipIcon from '~/app/components/Icon/SongwhipIcon';
import Box from '~/app/components/Box';

const HomePageNext = () => {
  return (
    <Page fullHeight>
      <Box coverParent centerContent>
        <SongwhipIcon size="8rem" />
      </Box>
    </Page>
  );
};

export default HomePageNext;
